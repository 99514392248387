type PortaalHeaderProps = {
  title: string;
  subtitle?: string;
};

export default function PortaalHeader({ title, subtitle }: PortaalHeaderProps) {
  return (
    <header className="header header--gradient" role="banner">
      <div className="base-container">
        <div className="header__wrapper header__wrapper--space-between">
          <div className="header__logo">
            {process.env.REACT_APP_P_STUDIO_GROUP === 'SO' && (
              <img
                data-testid="portaal-logo"
                src={process.env.PUBLIC_URL + '/images/portaal_logo.svg'}
                alt="Portaal logo"
              />
            )}
            {process.env.REACT_APP_P_STUDIO_GROUP === 'ES' && (
              <img
                data-testid="portail-logo"
                src={process.env.PUBLIC_URL + '/images/portail_logo.svg'}
                alt="Portail logo"
              />
            )}
          </div>
          <div className="header__content">
            <h1 className="header__title">{title}</h1>
            {subtitle && <div className="header__subtitle">{subtitle}</div>}
          </div>
        </div>
      </div>
    </header>
  );
}
